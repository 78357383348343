import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches permissions with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_role.role_path_view").get(`${version}/permission/view/`, { params })
}

/**
 * Fetches selected permissions with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getListSelected(params) {
  return Client("root_role.role_path_view").get(`${version}/permission/selected/`, { params })
}

/**
 * Fetches the detail of a permission by ID.
 * @param {string} id - The ID of the permission.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_role.role_path_detail", "detail").get(`${version}/permission/detail/${id}/`)
}

/**
 * Creates a new permission with the provided data.
 * @param {object} data - Data for creating the new permission.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_role.role_path_create").post(`${version}/permission/create/`, data)
}

/**
 * Updates a permission with the provided ID and data.
 * @param {string} id - The ID of the permission to be updated.
 * @param {object} data - Updated data for the permission.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_role.role_path_update").put(`${version}/permission/update/${id}`, data)
}

// API object containing the exported functions.
const api = {
  gets,
  getListSelected,
  getDetail,
  create,
  update
}

export default api
