import Client from "../client/ClientControlIDM"
import CPClient from "../client/ClientControlPlane"
import version from "../version"

/**
 * Fetches detailed information for a specific approval based on the provided ID.
 * @param {string} id - The ID of the approval for which detailed information is requested.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetailApproval(id) {
  return CPClient("root_approval.approval_path_detail", "detail").get(
    `${version}/internal/approvals/${id}/`
  )
}

/**
 * Fetches detailed information for a specific approval setting based on the provided code.
 * @param {string} code - The code of the approval setting for which detailed information is requested.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getApprovalSettingDetail(code) {
  return CPClient("root_approval_setting.approval_setting_path_detail", "detail").get(
    `${version}/internal/approval_settings/${code}/`
  )
}

/**
 * Fetches a list of approval histories with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getHistories(params) {
  return CPClient("root_approval.approval_path_view").get(
    `${version}/internal/approvals-histories/`,
    { params }
  )
}

/**
 * Requests approval with the provided data.
 * @param {object} data - Data for creating the new approval entry.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function requestApproval(data) {
  return CPClient("root_approval.approval_path_create").post(
    `${version}/internal/approvals/create/`,
    data
  )
}

/**
 * Requests approval with IDM integration using the specified parameters, data, and resource.
 * @param {object} params - Parameters for the approval request.
 * @param {object} data - Data for creating the new approval entry.
 * @param {string} resource - Resource for the IDM integration.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function requestApprovalIDM(params, data, resource) {
  return Client(resource, "APPROVAL").post(
    `${version}/request-approval/?action=${params.action}&obj=${params.obj}${
      params.id ? `&id=${params.id}` : ""
    }`,
    data
  )
}

/**
 * Updates the approval status for a specific approval entry based on the provided ID and data.
 * @param {string} id - The ID of the approval entry to be updated.
 * @param {object} data - Data for updating the approval entry.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function updateApprovalConfirm(id, data) {
  return CPClient("root_approval.approval_path_update").put(
    `${version}/internal/approvals/${id}/change_approval_status/`,
    data
  )
}

// API object containing the exported functions.
const api = {
  getDetailApproval,
  getApprovalSettingDetail,
  getHistories,
  requestApproval,
  requestApprovalIDM,
  updateApprovalConfirm
}

export default api
