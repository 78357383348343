import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches resources with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_resource.resource_path_view").get(`${version}/resource/view/`, { params })
}

/**
 * Fetches selected resources with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getListSelected(params) {
  return Client("root_resource.resource_path_view").get(`${version}/resource/selected/`, { params })
}

/**
 * Fetches the detail of a resource by ID.
 * @param {string} id - The ID of the resource.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_resource.resource_path_detail", "detail").get(
    `${version}/resource/detail/${id}`
  )
}

/**
 * Searches for resources with optional query parameters.
 * @param {object} params - Optional query parameters for the search.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function search(params) {
  return Client("root_resource.resource_path_view").get(`${version}/resource/search/`, { params })
}

/**
 * Creates a new resource with the provided data.
 * @param {object} data - Data for creating the new resource.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_resource.resource_path_create").post(`${version}/resource/create/`, data)
}

/**
 * Updates a resource with the provided ID and data.
 * @param {string} id - The ID of the resource to be updated.
 * @param {object} data - Updated data for the resource.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_resource.resource_path_update").put(`${version}/resource/update/${id}`, data)
}

/**
 * Removes a resource with the provided ID.
 * @param {string} id - The ID of the resource to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_resource.resource_path_delete").delete(`${version}/resource/delete/${id}`)
}

/**
 * Removes multiple resources using a batch request.
 * @param {object} data - Data specifying the resources to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function removeList(data) {
  return Client("root_resource.resource_path_batch_delete").post(
    `${version}/resource/delete/batch`,
    data
  )
}

// API object containing the exported functions.
const api = {
  gets,
  getListSelected,
  getDetail,
  search,
  create,
  update,
  remove,
  removeList
}

export default api
