// import { useRef, useState } from "react"
import { Modal, Button } from "antd"
import { useEffect } from "react"
import { iconWarning } from "lib/icon"
import "./index.css"
import { useTranslation } from "react-i18next"

export default function ModalPopup({ visible, content, onClose, onSubmit, loading, isNotCallAPI }) {
  const { t } = useTranslation()

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      className="modal_confirm"
      title={
        <div>
          <div className="flex justify-center">{iconWarning}</div>
          <div className="text-center">{t("461_3536")}</div>
        </div>
      }
      centered
      width={416}
      // height={!isNotCallAPI ? 220 : 250}
      open={visible}
      onCancel={onClose}
      onOk={onSubmit}
      closable={false}
      footer={[
        <div className="flex gap-4 justify-center mt-5">
          {!isNotCallAPI && (
            <Button key="back" onClick={onClose}>
              {t("461_3530")}
            </Button>
          )}

          <Button
            style={{ backgroundColor: "#FAAD14", boxShadow: "none" }}
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => (isNotCallAPI ? onClose() : onSubmit())}
          >
            {t("461_3532")}
          </Button>
        </div>
      ]}
    >
      <p className="mb-12 text-center text-gray-500">{content}</p>
    </Modal>
  )
}
