import { configLocale } from "lib/Const"
import Client from "../client/ClientAms"

// The resource path is constructed by appending the version and "/activity/logs" to form the base path.
const resource = "/api"

/**
 * Fetches a list of activity logs with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getTranslate(params) {
  return Client("root_all_resources").get(
    `${resource}/sdk/translation-elements/latest-version-v3`,
    {
      params: {
        design_file_id: configLocale.designFileId,
        limit: 1000000,
        ...params
      }
    }
  )
}

function getTranslateErrorMessage(params) {
  return Client("root_all_resources").get(`${resource}/sdk/system-errors`, {
    params: {
      design_file_id: configLocale.designFileId,
      limit: 10000,
      ...params
    }
  })
}

function getLanguages() {
  return Client("root_all_resources").get(`${resource}/config`)
}

/**
 * Fetches detailed information for a specific activity log based on the provided parameters.
 * @param {object} params - Parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */

// API object containing the exported functions.
const api = {
  getTranslate,
  getLanguages,
  getTranslateErrorMessage
}

export default api
