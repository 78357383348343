import { SERVICE_CODE } from "lib/Const"
import getInstanceAxios from "utils/request"

// The base domain is obtained from the environment variables.
const baseDomain = process.env.REACT_APP_API_GATEWAY_URL

// The base URL is constructed using the base domain.
const baseURL = `${baseDomain}/${SERVICE_CODE.API_COMMOM}/`

/**
 * Returns an Axios instance configured with common API settings.
 * The instance can be used for making requests to the common API.
 */
export default getInstanceAxios(baseURL)
