// Importing the js-cookie library for handling cookies
import Cookies from "js-cookie"

// Extracting the second-level domain from the current hostname
var parts = window.location.hostname.split(".")
var sndleveldomain = parts.slice(-2).join(".")

// Configuration object for cookies, specifying domain and expiration
const config = {
  domain: sndleveldomain,
  expires: 1 // Cookies expiration set to 3 days
}

// Function to set the "auth" cookie with authentication data
export function setCookieAuth(auth) {
  // Initialize an empty object for saving authentication data
  let authSave = {}
  // Check if the auth object is provided and not empty
  if (auth && Object.keys(auth)?.length) {
    authSave = auth
  }
  // Set the "auth" cookie with the serialized authentication data and specified config
  Cookies.set("auth", JSON.stringify(authSave), config)
}

// Function to retrieve authentication data from the "auth" cookie
export function getCookieAuth() {
  // Get the value of the "auth" cookie using the specified config
  const v = Cookies.get("auth", config)
  // If the value is present, parse and return the authentication data, otherwise return an empty object
  if (v) return JSON.parse(v)
  return {}
}

// Function to remove specific cookies, in this case, the "auth" cookie
export function removeCookies() {
  Cookies.remove("auth", config)
}

export function setI18nextLng(i18nextLng) {
  const lng = i18nextLng || "en"
  Cookies.set("i18nextLng", lng, config)
}
export function getI18nextLng() {
  return Cookies.get("i18nextLng", config)
}
