import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches a list of identity providers with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_identity_provider.identity_provider_path_view").get(
    `${version}/identity-provider/view/`,
    { params }
  )
}

/**
 * Fetches detailed information for a specific identity provider based on the provided ID.
 * @param {string} id - The ID of the identity provider for which detailed information is requested.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_identity_provider.identity_provider_path_detail", "detail").get(
    `${version}/identity-provider/detail/${id}`
  )
}

/**
 * Creates a new identity provider with the provided data.
 * @param {object} data - Data for creating the new identity provider.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_identity_provider.identity_provider_path_create").post(
    `${version}/identity-provider/create/`,
    data
  )
}

/**
 * Updates the information for a specific identity provider based on the provided ID and data.
 * @param {string} id - The ID of the identity provider to be updated.
 * @param {object} data - Data for updating the identity provider.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_identity_provider.identity_provider_path_update").put(
    `${version}/identity-provider/update/${id}`,
    data
  )
}

/**
 * Updates the status of multiple identity providers based on the provided data.
 * @param {object} data - Data for updating the status of multiple identity providers.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function updateStatus(data) {
  return Client("root_identity_provider.identity_provider_path_batch_update_status").post(
    `${version}/identity-provider/status/batch`,
    data
  )
}

/**
 * Removes a specific identity provider based on the provided ID.
 * @param {string} id - The ID of the identity provider to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_identity_provider.identity_provider_path_delete").delete(
    `${version}/identity-provider/delete/${id}`
  )
}

/**
 * Removes multiple identity providers based on the provided data.
 * @param {object} data - Data for removing multiple identity providers.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function removeList(data) {
  return Client("root_identity_provider.identity_provider_path_batch_delete").post(
    `${version}/identity-provider/delete/batch`,
    data
  )
}

// API object containing the exported functions.
const api = {
  gets,
  getDetail,
  create,
  update,
  updateStatus,
  remove,
  removeList
}

export default api
