import Client from "../client/ClientAssetControl"
import version from "../version"
const resource = version + "/client/driver"

function gets(params) {
  return Client("root_driver.driver_path_view").get(`${resource}/list/`, { params })
}
function get(id) {
  return Client("root_driver.driver_path_detail").get(`${resource}/retrieve/${id}/`)
}
function getStatistic(id, params) {
  return Client("root_driver.driver_path_view").get(`${resource}/rating/statistic/${id}/`, {
    params
  })
}
function getActivityStatistic(id, params) {
  return Client("root_driver.driver_path_view").get(`${resource}/activity/log/${id}/`, {
    params
  })
}
function getWorkReports(id, params) {
  return Client("root_driver.driver_path_view").get(`${resource}/retrieve/${id}/working-report/`, {
    params
  })
}
function getListRating(id, params) {
  return Client("root_driver.driver_path_view").get(`${resource}/rating/${id}/`, { params })
}
function remove(id) {
  return Client("root_driver.driver_path_delete").delete(`${resource}/retrieve/${id}/`)
}
function sendInvitation(id) {
  return Client("root_driver.driver_path_update").put(`${resource}/send-invitation/${id}/`)
}
function create(data) {
  return Client("root_driver.driver_path_create").post(`${resource}/create/`, data)
}
function update(id, data) {
  return Client("root_driver.driver_path_update").put(`${resource}/retrieve/${id}/`, data)
}

function exportDriver(data) {
  return Client("root_driver.driver_path_export").post(`${resource}/export/`, data)
}

const api = {
  gets,
  get,
  getStatistic,
  getActivityStatistic,
  getListRating,
  getWorkReports,
  create,
  update,
  remove,
  sendInvitation,
  exportDriver
}
export default api
