import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches a list of roles with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_role.role_path_view").get(`${version}/role/view/`, { params })
}

/**
 * Fetches a list of selected roles with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getListSelected(params) {
  return Client("root_role.role_path_view").get(`${version}/role/selected/`, { params })
}

/**
 * Fetches detailed information for a specific role based on the provided ID.
 * @param {string} id - The ID of the role for which detailed information is requested.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_role.role_path_detail", "detail").get(`${version}/role/detail/${id}`)
}

/**
 * Creates a new role with the provided data.
 * @param {object} data - Data for creating the new role.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_role.role_path_create").post(`${version}/role/create/`, data)
}

/**
 * Updates the information for a specific role based on the provided ID and data.
 * @param {string} id - The ID of the role to be updated.
 * @param {object} data - Data for updating the role.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_role.role_path_update").put(`${version}/role/update/${id}`, data)
}

/**
 * Removes a specific role based on the provided ID.
 * @param {string} id - The ID of the role to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_role.role_path_delete").delete(`${version}/role/delete/${id}`)
}

/**
 * Removes multiple roles based on the provided data.
 * @param {object} data - Data for removing multiple roles.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function removeList(data) {
  return Client("root_role.role_path_batch_delete").post(`${version}/role/delete/batch`, data)
}

// API object containing the exported functions.
const api = {
  gets,
  getListSelected,
  getDetail,
  create,
  update,
  remove,
  removeList
}

export default api
