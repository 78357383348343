// Importing necessary modules and utilities
import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { notification } from "antd"
import { getCookieAuth, removeCookies } from "lib/cookie"
import { getAuthLocal, setAuthLocal } from "lib/localstorage"
import { ERROR_API_CODE } from "lib/Const"
import { v4 as uuidv4 } from "uuid"
import { getI18nextLng } from "lib/cookie"

let globalId = uuidv4()
// Function to handle the logic for refreshing the authentication token
export const refreshAuthLogic = (failedRequest) => {
  return axios
    .post(`${process.env.REACT_APP_IDM_URL}/api/v1/account/refresh/`, {
      refresh_token: getCookieAuth()?.refresh_token
    })
    .then((tokenRefreshResponse) => {
      // Update the access token in local storage
      localStorage.setItem("accessToken", tokenRefreshResponse.data.token)

      // Update the Authorization header for the failed request with the new token
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.token

      return Promise.resolve()
    })
    .catch((err) => {
      if (!!err?.response?.request?.status) {
        // Clear local storage and cookies on token refresh failure
        // Redirect to login page
        const data = {
          error_message: "Session has expired. Please log in again."
        }
        showError(data)
        setTimeout(() => {
          localStorage.clear()
          removeCookies()
          window.location.href = "/login"
        }, 3000)
      }
    })
}

// Function to display error messages using Ant Design notifications
export function showError(data) {
  const languageCode = getI18nextLng()
  const amsErrorMsgs = JSON.parse(localStorage.getItem("errorMsg"))
  const messageAfterTranslate = getMessageAfterTranslate(data, languageCode, amsErrorMsgs)
  if (messageAfterTranslate) {
    notification.error({
      message: messageAfterTranslate
    })
  } else if (data && Array.isArray(data?.error_message) && data?.error_message?.length > 0) {
    data?.error_message.forEach((item) => {
      if (item.message) {
        // Display error message with specified duration
        notification.error({
          message: item.message,
          duration: 3
        })
      } else {
        // Display error message with specified duration
        notification.error({
          message: item,
          duration: 3
        })
      }
    })
  } else {
    // Display error message with specified duration
    notification.error({
      message: data?.message || data?.error_message || data?.error
    })
  }
}

function getMessageAfterTranslate(data, languageCode, amsErrorMsgs) {
  const amsErrorMsg = amsErrorMsgs?.find(
    (item) => item?.language_code === languageCode && item?.error_code === data?.error_code
  )
  return amsErrorMsg?.content || undefined
}

// Function to create an instance of Axios with custom interceptors
export default function getInstanceAxios(
  baseAPI,
  resource,
  service_code,
  method,
  project_id,
  action,
  requestId
) {
  // Create an instance of Axios with specified base URL
  const instance = axios.create({
    baseURL: baseAPI
  })

  // Request interceptor to set custom headers and handle authentication
  instance.interceptors.request.use(
    function (config) {
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        method:
          method?.toUpperCase() || (config.method === "get" ? "VIEW" : config.method.toUpperCase()),
        "Http-global-id": uuidv4(),
        "Device-Id": globalId
      }

      // Check for token changes and update headers accordingly
      const oldToken = getAuthLocal()?.access_token
      const { access_token } = getCookieAuth()
      if (oldToken && oldToken !== access_token) {
        setAuthLocal({})
        window.location.href = "/login"
      }
      if (access_token) config.headers["Authorization"] = `Bearer ${access_token}`

      // Set custom headers for resource and service code
      config.headers.resource = resource
      config.headers.service = service_code
      if (project_id) config.headers["project-id"] = project_id
      if (action) config.headers["action"] = action
      if (requestId) config.headers["request-id"] = requestId
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  // Response interceptor to handle successful responses and errors
  instance.interceptors.response.use(
    function (response) {
      try {
        // Check if the response status is in the success range
        if (response.status >= 200 && response.status < 300) return response.data
        // Reject the promise for non-successful responses
        return Promise.reject(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async function (error) {
      // Handle errors and status code 401 (Unauthorized)
      if (error.response) {
        const { response } = error
        if (response.status === 401) removeCookies()

        // Extract error data from the response
        const data = response.data

        // Handle specific error codes and show error notifications
        if (
          data &&
          (data.error_code === ERROR_API_CODE.Err_Get_Not_Found ||
            data.error_code === ERROR_API_CODE.Permission_Not_Allow ||
            data.error_code === ERROR_API_CODE.User_Not_Found)
        ) {
          window.setTimeout(() => {
            window.history.back()
          }, 800)
          showError(data)
        } else if (data.error_code === ERROR_API_CODE.Wrapper_Url_Not_Found) {
          // Clear local cookie & Redirect to login page
          localStorage.clear()
          removeCookies()
          window.location.href = "/login"
        } else {
          showError(data)
        }
      }
      return Promise.reject(error)
    }
  )

  // Attach authentication token refresh logic to the Axios instance
  createAuthRefreshInterceptor(instance, refreshAuthLogic)

  // Return the configured Axios instance
  return instance
}
