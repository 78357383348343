import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Performs a user login by sending login data to the server.
 * @param {object} data - User login data.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
const login = (data) => {
  return Client("root_admin_user.admin_user_path_create").post(`${version}/account/login/`, data)
}

/**
 * Performs user logout by sending logout data to the server.
 * @param {object} data - Logout data.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
const logout = (data) => {
  return Client("root_admin_user.admin_user_path_create").post(`/api/v1/account/logout/`, data)
}

/**
 * Fetches the user profile data.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getProfile() {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/account/view/profile/`)
}

/**
 * Fetches user menus from the server.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getMenus() {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/menus/`)
}

/**
 * Fetches user permissions with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
const getPermissions = (params) => {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/account/permissions/`, {
    params
  })
}

/**
 * Fetches the history of personal data changes for the user.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
const getPersonalDataHistory = (params) => {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/personal-data/list/`, {
    params
  })
}

/**
 * Updates the user profile with the provided data.
 * @param {object} data - Updated profile data.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function updateProfile(data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/update/profile/`,
    data
  )
}

/**
 * Changes the user password with the provided data.
 * @param {object} data - Data for changing the password.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function changePassword(data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/change-password/`,
    data
  )
}

/**
 * Removes the user password with the provided ID.
 * @param {string} id - The ID of the password to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_admin_user.admin_user_path_delete").delete(
    `${version}/account/password/${id}/`
  )
}

/**
 * Sends an email with the provided data.
 * @param {object} data - Data for sending the email.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function sendEmail(data) {
  return Client("root_admin_user.admin_user_path_create").post(
    `${version}/mail/generate-code/`,
    data
  )
}

/**
 * Verifies a code sent via email with the provided data.
 * @param {object} data - Data for verifying the code.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function verifyCode(data) {
  return Client("root_admin_user.admin_user_path_create").post(`${version}/mail/verify-code/`, data)
}

// API object containing the exported functions.
const api = {
  login,
  logout,
  changePassword,
  remove,
  getProfile,
  getMenus,
  getPermissions,
  updateProfile,
  sendEmail,
  verifyCode,
  getPersonalDataHistory
}

export default api
