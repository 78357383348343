import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches a list of end users with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_end_user.end_user_path_view").get(`${version}/enduser/view/`, { params })
}

/**
 * Fetches detailed information for a specific end user based on the provided ID.
 * @param {string} id - The ID of the end user for which detailed information is requested.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_end_user.end_user_path_detail", "detail").get(
    `${version}/enduser/detail/${id}`
  )
}

/**
 * Creates a new end user with the provided data.
 * @param {object} data - Data for creating the new end user.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_end_user.end_user_path_create").post(`${version}/enduser/create/`, data)
}

/**
 * Updates the information for a specific end user based on the provided ID and data.
 * @param {string} id - The ID of the end user to be updated.
 * @param {object} data - Data for updating the end user.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_end_user.end_user_path_update").put(`${version}/enduser/update/${id}`, data)
}

/**
 * Updates the status of multiple end users based on the provided data.
 * @param {object} data - Data for updating the status of multiple end users.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function updateStatus(data) {
  return Client("root_end_user.end_user_path_batch_update_status").post(
    `${version}/enduser/status/batch`,
    data
  )
}

/**
 * Removes a specific end user based on the provided ID.
 * @param {string} id - The ID of the end user to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_end_user.end_user_path_delete").delete(`${version}/enduser/delete/${id}`)
}

/**
 * Removes multiple end users based on the provided data.
 * @param {object} data - Data for removing multiple end users.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function removeList(data) {
  return Client("root_end_user.end_user_path_batch_delete").post(
    `${version}/enduser/delete/batch`,
    data
  )
}

/**
 * Fetches dashboard information for end users.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDashboard() {
  return Client("root_end_user.end_user_path_view_dashboard").get(`${version}/enduser/dashboard/`)
}

/**
 * Fetches statistics related to end users.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getStatistics() {
  return Client("root_end_user.end_user_path_view_statistic").get(`${version}/enduser/statistic/`)
}

// API object containing the exported functions.
const api = {
  gets,
  getDetail,
  create,
  update,
  updateStatus,
  remove,
  removeList,
  getStatistics,
  getDashboard
}

export default api
