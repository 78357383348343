// Importing necessary components and icons from Ant Design and other dependencies
import { Form, Input, Button, Card, Checkbox } from "antd"
import { MailOutlined, LockOutlined } from "@ant-design/icons"
import { apiUser } from "api"
import { useState } from "react"
import { setCookieAuth } from "lib/cookie"
import { useTranslation } from "react-i18next"

// Validation messages for the form
const validateMessages = {
  types: {
    // Custom error message for email validation
    // eslint-disable-next-line no-template-curly-in-string
    email: "${label} is not a valid email!"
  }
}

// Functional component for the Login screen
const Login = () => {
  const { t } = useTranslation()
  // State variable to manage loading state of the form
  const [loading, setLoading] = useState(false)

  // Function to handle form submission
  const onFinish = async (values) => {
    // Setting loading state to true during the API call
    setLoading(true)
    try {
      // Making API call to login user
      let res = {}
      res = await apiUser.login(values)
      // Extracting access_token and refresh_token from the response
      const { access_token, refresh_token } = res.data
      // Setting authentication cookies
      setCookieAuth({ access_token, refresh_token })
      // Reloading the window after successful login
      window.location.reload()
    } catch (error) {
      // Handling errors, if any
    } finally {
      // Resetting loading state to false after the API call
      setLoading(false)
    }
  }

  // Function to perform demo login (using default credentials)
  const onLogin = () => {
    const values = {
      username: "admin@organiclink.cloud",
      password: "@U58KZpz7cr0"
    }
    onFinish(values)
  }

  // Checking if the application is in demo mode based on the origin
  const isDemo = !window.location.origin.includes("com")

  // Rendering the component
  return (
    <div>
      {/* Fixed container covering the whole screen with centered content */}
      <div className="fixed w-full inset-0 flex items-center justify-center">
        {/* Card component for styling the login form */}
        <Card className="max-w-md w-full">
          {/* Logo */}
          <div className="flex justify-center mb-5">
            <img
              src={
                "https://stvinaprod.vtvcab.vn/58b0926d-7b1a-4753-a025-b6ab375e46ee.png?auto=format&fit=max&w=256"
              }
              alt={t("30_4271")}
            />
          </div>

          {/* Form for user login */}
          <Form
            layout="vertical"
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            {/* Input field for email */}
            <Form.Item
              label={t("32_4691")}
              name="username"
              rules={[{ required: true, type: "email" }]}
            >
              <Input size="large" prefix={<MailOutlined />} placeholder={t("184_24608")} />
            </Form.Item>

            {/* Input field for password */}
            <Form.Item
              label={t("212_1571")}
              name="password"
              rules={[{ required: true, message: t("212_1625") }]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder={t("184_23905")} />
            </Form.Item>

            {/* Remember me checkbox and Forgot Password link */}
            <div className="flex justify-between">
              <Form.Item name="remember" valuePropName="checked" className="m-0">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <a className="!text-black" href="/forgot-password">
                Forgot Password?
              </a>
            </div>

            {/* Demo Login button (visible only in demo mode) */}
            {isDemo && (
              <div className="flex justify-center p-2 gap-2">
                <Button onClick={onLogin}>DemoLogin</Button>
              </div>
            )}

            {/* Submit button for user login */}
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="w-full"
                loading={loading}
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>

          {/* Copyright information */}
          <p className="text-center">{t("294_1867")}</p>
          <p className="text-center">
            <a className="!text-black !underline" href="/">
              {t("294_1868")}
            </a>{" "}
            {t("294_1864")}{" "}
            <a className="!text-black !underline" href="/">
              {t("294_1870")}
            </a>
          </p>
        </Card>
      </div>
    </div>
  )
}

// Exporting the Login component as the default export
export default Login
