import { SERVICE_CODE } from "lib/Const"
import getInstanceAxios from "utils/request"

// The base domain for the control plane service is obtained from the environment variables.
const baseDomain = process.env.REACT_APP_API_GATEWAY_URL

// The base URL for the control plane service is constructed using the base domain.
const baseURL = `${baseDomain}/${SERVICE_CODE.API_CONTROL_PLANE}/`

/**
 * Returns an Axios instance configured with control plane API settings.
 * The instance can be used for making requests to the control plane API.
 * @param {string} resource - The resource or endpoint to be appended to the base URL.
 * @param {string} method - The HTTP method (GET, POST, PUT, DELETE, etc.) for the request.
 * @returns {AxiosInstance} - Axios instance configured for the control plane API.
 */
export default function clientGateWay(resource, method) {
  return getInstanceAxios(baseURL, resource, SERVICE_CODE.API_CONTROL_PLANE, method)
}
