// import { useRef, useState } from "react"
import { Modal, Button } from "antd"
import dayjs from "dayjs"
import { getAuthLocal } from "lib/localstorage"
import { useTranslation } from "react-i18next"

export default function ModalApproval({ visible, onClose, onSubmit, data, loading }) {
  const { user } = getAuthLocal()
  const { t } = useTranslation()

  return (
    <Modal
      title={t("547_1363")}
      centered
      width={350}
      height={300}
      open={visible}
      onCancel={onClose}
      onOk={onSubmit}
      footer={[
        <Button key="back" onClick={onClose}>
          {t("547_1376")}
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
          {t("547_1377")}
        </Button>
      ]}
    >
      <div>
        <div className="flex justify-between">
          <p>{t("547_1364")}</p>
          <div className="pl-2 w-2/3 text-left bg-slate-200">{data?.name}</div>
        </div>
        <div className="flex justify-between mt-2">
          <p>{t("547_1365")}</p>
          <div className="pl-2 w-2/3 text-left bg-slate-200">{data?.id}</div>
        </div>
        <div className="flex justify-between mt-2">
          <p>{t("547_1366")}</p>
          <div className="pl-2 w-2/3 text-left bg-slate-200">{user?.email}</div>
        </div>
        <div className="flex justify-between mt-2">
          <p>{t("547_1367")}</p>
          <div className="pl-2 w-2/3 text-left bg-slate-200">{t("547_1372")}</div>
        </div>
        <div className="flex justify-between mt-2">
          <p>{t("547_1368")}</p>
          <div className="pl-2 w-2/3 text-left bg-slate-200">
            {dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}
          </div>
        </div>
      </div>
    </Modal>
  )
}
