import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches RBAC permissions with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_rbac_permission.rbac_permission_path_view").get(
    `${version}/rbac-permission/view/`,
    { params }
  )
}

/**
 * Fetches selected RBAC permissions with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getListSelected(params) {
  return Client("root_rbac_permission.rbac_permission_path_view").get(
    `${version}/rbac-permission/selected/`,
    { params }
  )
}

/**
 * Fetches the detail of an RBAC permission by ID.
 * @param {string} id - The ID of the RBAC permission.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_rbac_permission.rbac_permission_path_detail", "detail").get(
    `${version}/rbac-permission/detail/${id}/`
  )
}

/**
 * Creates a new RBAC permission with the provided data.
 * @param {object} data - Data for creating the new RBAC permission.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_rbac_permission.rbac_permission_path_create").post(
    `${version}/rbac-permission/create/`,
    data
  )
}

/**
 * Updates an RBAC permission with the provided ID and data.
 * @param {string} id - The ID of the RBAC permission to be updated.
 * @param {object} data - Updated data for the RBAC permission.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_rbac_permission.rbac_permission_path_update").put(
    `${version}/rbac-permission/update/${id}`,
    data
  )
}

/**
 * Removes an RBAC permission with the provided ID.
 * @param {string} id - The ID of the RBAC permission to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_rbac_permission.rbac_permission_path_delete").delete(
    `${version}/rbac-permission/delete/${id}`
  )
}

// API object containing the exported functions.
const api = {
  gets,
  getListSelected,
  getDetail,
  create,
  update,
  remove
}

export default api
