import { useState, useEffect, useRef } from "react"

// Custom React hook for managing the visibility of a component
export default function useComponentVisible(initialIsVisible) {
  // State variable to track the visibility of the component
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)

  // Reference to the DOM element of the component
  const ref = useRef(null)

  // Function to handle clicks outside of the component to hide it
  const handleClickOutside = (event) => {
    // Check if the clicked element is outside the component's DOM element
    if (ref.current && !ref.current.contains(event.target)) {
      // Set the visibility state to false
      setIsComponentVisible(false)
    }
  }

  // Effect hook to add and remove a global click event listener
  useEffect(() => {
    // Add a click event listener to the document when the component is mounted
    document.addEventListener("click", handleClickOutside, true)

    // Cleanup function to remove the click event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, []) // Empty dependency array ensures that the effect runs only on mount and unmount

  // Return an object containing the component's ref, visibility state, and a function to set visibility
  return { ref, isComponentVisible, setIsComponentVisible }
}
