import { SERVICE_CODE } from "lib/Const"
import getInstanceAxios from "utils/request"

const baseDomain = process.env.REACT_APP_IOT_ACTIVITY_URL
const baseURL = `${baseDomain}/${SERVICE_CODE.API_IOT_ACTIVITY}/`

export default function ClientChauffeur(resource, method, action, requestId) {
  return getInstanceAxios(
    baseURL,
    resource,
    SERVICE_CODE.API_CHAUFFEUR,
    method,
    undefined,
    action,
    requestId
  )
}
