// Function to retrieve permissions from local storage or return an empty array if not found
export function getPermissions() {
  // Check if "permissions" key exists in local storage
  if (localStorage.getItem("permissions")) {
    // Return the stored permissions if available
    return localStorage.getItem("permissions")
  } else {
    // Return an empty array as a string if "permissions" key is not found
    return "[]"
  }
}

export function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
