// Importing the query-string library for handling URL query parameters
import queryString from "query-string"
// Importing the getConfig function from the localstorage module
import { getConfig } from "./localstorage"

// Function to convert a duration in seconds to a clock format (HH:MM:SS)
export function convertDurationToClock(n) {
  let h = Math.floor(n / 3600)
  let m = n % 3600
  let _m = Math.floor(m / 60)
  let s = m % 60
  if (h === 0) {
    h = ""
  } else {
    h = h + ":"
  }
  if (_m < 10) _m = "0" + _m
  if (s < 10) s = "0" + s
  return h + _m + ":" + s
}

// Function to convert data tree structure for UI display
export function converDataTree(_list, filter) {
  let list = [..._list]
  function loop(__list) {
    for (let i = 0; i < __list.length; i++) {
      __list[i].value = __list[i].id
      __list[i].title = __list[i].name

      if (__list[i].children && __list[i].children.length > 0) {
        if (filter) {
          __list[i].selectable = true
        } else {
          __list[i].selectable = false
        }
        loop(__list[i].children)
      }
    }
  }
  loop(list)
  return list
}

// Object containing functions for handling URL parameters
export const paramsUrl = {
  // Function to get parsed URL parameters
  get: () => {
    return queryString.parse(window.location.search)
  },
  // Function to set URL parameters
  set: (params) => {
    const currentUrlParams = queryString.stringify(params, {
      skipNull: true
    })
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${currentUrlParams.toString()}`
    )
  }
}

// Function to get parsed search parameters from the URL
export function getSearchParams() {
  return queryString.parse(window.location.search)
}

// Function to get the user role from local storage
export function getRole() {
  const role = localStorage.getItem("role")
  return role || false
}

// Function to format a number as a currency string
export function fomatCurrency(number) {
  if (number) {
    return parseInt(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
  }
  return 0
}
export default function convertTransalte() {
  let translates = {
    en: "English"
  }
  const translatesStore = localStorage.getItem("language")
  if (translatesStore) translates = JSON.parse(translatesStore)
  let rows = Object.keys(translates).map((i) => {
    return {
      label: translates[i],
      value: i
    }
  })
  return rows
}

// Function to get the access token from local storage
export function getToken() {
  return localStorage.getItem("accessToken")
}

// Function to get the label for a configuration type and value
export function getNameConfig(type, value) {
  const typeConfigs = getConfig(type)
  return typeConfigs.find((i) => i.value === value)?.label
}

// Function to convert a relative link to an absolute link using a base URL
export function convertLink(url, baseUrl) {
  const baseLink = getConfig("base_link")
  if (url?.includes("http")) return url
  return baseUrl ? baseUrl + url : baseLink?.url_img + url
}

// Function to convert a permission object into a more structured format
export function convertPermission(permission) {
  const list = Object.keys(permission)
  let objPermission = {}
  for (let i = 0; i < list.length; i++) {
    let __path = list[i]?.split(".")[0]
    let values = permission[list[i]]
    if (!objPermission[__path]) objPermission[__path] = {}
    for (let i = 0; i < values.length; i++) {
      if (values[i].method === "*") {
        objPermission[__path] = {
          DELETE: true,
          POST: true,
          PUT: true,
          GET: true,
          CLOSE: true,
          DETAIL: true,
          ATRACT: true,
          APPROVAL: true,
          VIEW: true,
          IMPORT: true
        }
        break
      } else {
        objPermission[__path][values[i].method] = true
      }
    }
  }
  return objPermission
}

export function findDiff(options1, options2) {
  const values2 = new Set(options2.map((item) => item.value))
  const result = options1.filter((item) => !values2.has(item.value))
  return result
}
