import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches user data with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/user/view/`, { params })
}

/**
 * Fetches the detail of a user by ID.
 * @param {string} id - The ID of the user.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_admin_user.admin_user_path_detail", "detail").get(
    `${version}/user/detail/${id}`
  )
}

/**
 * Creates a new user with the provided data.
 * @param {object} data - Data for creating the new user.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_admin_user.admin_user_path_create").post(`${version}/user/create/`, data)
}

/**
 * Updates a user with the provided ID and data.
 * @param {string} id - The ID of the user to be updated.
 * @param {object} data - Updated data for the user.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_admin_user.admin_user_path_update").put(`${version}/user/update/${id}`, data)
}

/**
 * Updates the status of multiple users using a batch request.
 * @param {object} data - Data specifying the users and status updates.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function updateStatus(data) {
  return Client("root_admin_user.admin_user_path_batch_update_status").post(
    `${version}/user/status/batch`,
    data
  )
}

/**
 * Resets the password of multiple users using a batch request.
 * @param {object} data - Data specifying the users for password reset.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function resetPasswordListUser(data) {
  return Client("root_admin_user.admin_user_path_batch_update_status").put(
    `${version}/user/reset/batch/`,
    data
  )
}

/**
 * Removes a user with the provided ID.
 * @param {string} id - The ID of the user to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_admin_user.admin_user_path_delete").delete(`${version}/user/delete/${id}`)
}

/**
 * Removes multiple users using a batch request.
 * @param {object} data - Data specifying the users to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function removeList(data) {
  return Client("root_admin_user.admin_user_path_batch_delete").post(
    `${version}/user/delete/batch`,
    data
  )
}

/**
 * Fetches dashboard data for users.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDashboard() {
  return Client("root_dashboard_idm.dashboard_idm_path_view").get(`${version}/user/dashboard/`)
}

/**
 * Fetches statistical data for users.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getStatistics() {
  return Client("root_admin_user.admin_user_path_view_statistic").get(`${version}/user/statistic/`)
}

// API object containing the exported functions.
const api = {
  gets,
  getDetail,
  create,
  update,
  updateStatus,
  resetPasswordListUser,
  remove,
  removeList,
  getStatistics,
  getDashboard
}

export default api
