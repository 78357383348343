import { SERVICE_CODE } from "lib/Const"
import getInstanceAxios from "utils/request"

// The base domain for the in-app notification service is obtained from the environment variables.
const baseDomain = process.env.REACT_APP_API_GATEWAY_URL

// The base URL for the in-app notification service is constructed using the base domain.
const baseURL = `${baseDomain}/${SERVICE_CODE.API_NOTI_INAPP}/`

/**
 * Returns an Axios instance configured with in-app notification API settings.
 * The instance can be used for making requests to the in-app notification API.
 * @param {string} resource - The resource or endpoint to be appended to the base URL.
 * @returns {AxiosInstance} - Axios instance configured for the in-app notification API.
 */
export default function ClientNotification(resource) {
  return getInstanceAxios(baseURL, resource, SERVICE_CODE.API_NOTI_INAPP)
}
