import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches a list of application views with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_idm_application.application_path_view").get(`${version}/application/view/`, {
    params
  })
}

/**
 * Fetches detailed information for a specific application based on the provided ID.
 * @param {string} id - The ID of the application for which detailed information is requested.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_idm_application.application_path_detail").get(
    `${version}/application/detail/${id}`
  )
}

/**
 * Creates a new application entry with the provided data.
 * @param {object} data - Data for creating the new application entry.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_idm_application.application_path_create").post(
    `${version}/application/create/`,
    data
  )
}

/**
 * Updates an existing application entry with the specified ID using the provided data.
 * @param {string} id - The ID of the application entry to be updated.
 * @param {object} data - Data for updating the application entry.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_idm_application.application_path_update").put(
    `${version}/application/update/${id}`,
    data
  )
}

/**
 * Removes an application entry with the specified ID.
 * @param {string} id - The ID of the application entry to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_idm_application.application_path_delete").delete(
    `${version}/application/delete/${id}`
  )
}

// API object containing the exported functions.
const api = {
  gets,
  getDetail,
  create,
  update,
  remove
}

export default api
