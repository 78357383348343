import React, { useState } from "react"
import Icon from "@ant-design/icons"
import MyHeader from "./Header/Header"
import { useStore } from "components/ui"
import Navbar from "./Navbar"
import "./index.css"
import menus from "pages/Authen/menus"

function convertMenus(menus, permission, user) {
  let __menus = []
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i]
    const IconX = () => <span dangerouslySetInnerHTML={{ __html: menu.icon }} />
    let __children = []
    const newChildren = menu.sub_menu
    const menuPermission = permission[menu.code]
    if (newChildren?.length > 0) {
      for (let j = 0; j < newChildren?.length; j++) {
        const IconY = () => <span dangerouslySetInnerHTML={{ __html: newChildren[j].icon }} />
        const item = {
          url: menu.url + newChildren[j].url,
          title: newChildren[j].title,
          icon: <Icon component={IconY} />
        }
        const menuPermissionSub = permission[newChildren[j].code]
        if (user?.is_super_user) {
          if (menuPermissionSub?.GET || newChildren[j].code === "all") __children.push(item)
        } else {
          if (
            (menuPermissionSub?.GET || newChildren[j].code === "all") &&
            !newChildren[j]?.is_super_user
          )
            __children.push(item)
        }
      }
    }
    if (user?.is_super_user) {
      if (menuPermission?.GET || menu.code === "all") {
        __menus.push({
          url: menu.url,
          title: menu.title,
          icon: <Icon component={IconX} />,
          children: __children
        })
      }
    } else {
      if ((menuPermission?.GET || menu.code === "all") && !menu?.is_super_user) {
        __menus.push({
          url: menu.url,
          title: menu.title,
          icon: <Icon component={IconX} />,
          children: __children
        })
      }
    }
  }

  return __menus
}

function MyLayout({ children }) {
  const isShow = localStorage.getItem("isShow")
  const { user, permission } = useStore()
  const [isNav, setIsNav] = useState(isShow === "false" ? false : true)

  function togleSidebar(e) {
    localStorage.setItem("isShow", !isNav)
    setIsNav((c) => !c)
  }
  
  return (
    <div className="flex min-h-screen">
      <Navbar items={convertMenus(menus, permission, user)} isNav={isNav} />
      <section className="flex-grow w-0 bg_layout_content">
        <header className="flex justify-between py-2 border-b bg-white pr-6 pl-4 h-14 w-full sticky top-0 z-20">
          <div className="flex items-center gap-4">
            <div
              onClick={togleSidebar}
              className={`text-black rounded cursor-pointer flex items-center`}
            >
              {icD}
            </div>
          </div>

          <MyHeader user={user} />
        </header>
        <div className="p-6">{children}</div>
      </section>
    </div>
  )
}
export default MyLayout

const icD = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-8 w-8"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeMiterlimit={2}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h8m-8 6h16" />
  </svg>
)
