// Importing necessary dependencies and components
import { apiUser } from "api"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useStore } from "components/ui"
import { getCookieAuth } from "lib/cookie"
import { convertPermission } from "lib/function"
import { RESOURCE_KEY } from "lib/Const"
import LoginForm from "./Login"
import { apiAms } from "api"

import SuspenseComponent from "components/Router/SuspenseComponent"
import { changeLanguage } from "utils/i18n"

// Functional component for handling user authentication and login
const Login = () => {
  // Destructuring functions from useStore hook
  const { setAuth } = useStore()
  // Access to the navigation object
  const navigate = useNavigate()

  // Checking if the application is running in a local environment
  const local = window.location.href.includes("local")

  // Initialization function to check authentication status and perform necessary actions
  async function init() {
    try {
      // Get access_token from cookies
      const { access_token } = getCookieAuth()
      let tabId = sessionStorage.tabID
      if (!tabId) {
        tabId = Math.random()
        sessionStorage.tabID = tabId
      }

      if (access_token && tabId) {
        // If access_token exists, fetch user-related data
        // const menus = await apiUser.getMenus()
        const userInfo = await apiUser.getProfile()
        const permission = await apiUser.getPermissions()

        // Extracting organization name from user information
        const orgName = userInfo?.data?.organization?.name

        // Set user authentication information in the global state
        const defaultLanguage = userInfo?.data?.language || "en"
        await changeLanguage(defaultLanguage)

        setAuth({
          user: userInfo?.data,
          orgName: orgName,
          permission: convertPermission(permission?.data),
          access_token: access_token
        })
        handleSetDataTranslateErrorMsg()
        // Redirect based on user permissions
        if (convertPermission(permission?.data)[RESOURCE_KEY.ROOT_DASHBOARD] === undefined) {
          navigate("/profile")
        } else {
          navigate("/dashboards")
        }
      } else {
        // If access_token doesn't exist
        sessionStorage.tabID = Math.random()
        if (!local) {
          // Redirect to Single Sign-On (SSO) login page
          window.location.href = `${process.env.REACT_APP_SSO_URL}`
        }
      }
    } catch (error) {
      // Handle errors, if any
    }
  }

  async function handleSetDataTranslateErrorMsg() {
    try {
      const { data } = await apiAms.getTranslateErrorMessage()
      const errorMsg =
        data &&
        data.length &&
        data.map((item) => ({
          error_code: item.error_code,
          content: item.content,
          language_code: item.language_code
        }))
      errorMsg && localStorage.setItem("errorMsg", JSON.stringify(errorMsg))
    } catch (error) {
      console.log(error)
    }
  }

  // useEffect hook to run the init function on component mount
  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Render the LoginForm component only in the local environment
  return <div>{local ? <LoginForm /> : <SuspenseComponent />}</div>
}

// Exporting the Login component as the default export
export default Login
