import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches RBAC group permissions with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_rbac_group_permission.rbac_group_permission_path_view").get(
    `${version}/rbac-group-permission/view/`,
    { params }
  )
}

/**
 * Fetches selected RBAC group permissions with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getListSelected(params) {
  return Client("root_rbac_group_permission.rbac_group_permission_path_view").get(
    `${version}/rbac-group-permission/selected/`,
    { params }
  )
}

/**
 * Fetches the detail of an RBAC group permission by ID.
 * @param {string} id - The ID of the RBAC group permission.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_rbac_group_permission.rbac_group_permission_path_detail", "detail").get(
    `${version}/rbac-group-permission/detail/${id}`
  )
}

/**
 * Creates a new RBAC group permission with the provided data.
 * @param {object} data - Data for creating the new RBAC group permission.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_rbac_group_permission.rbac_group_permission_path_create").post(
    `${version}/rbac-group-permission/create/`,
    data
  )
}

/**
 * Updates an RBAC group permission with the provided ID and data.
 * @param {string} id - The ID of the RBAC group permission to be updated.
 * @param {object} data - Updated data for the RBAC group permission.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_rbac_group_permission.rbac_group_permission_path_update").put(
    `${version}/rbac-group-permission/update/${id}`,
    data
  )
}

/**
 * Removes an RBAC group permission with the provided ID.
 * @param {string} id - The ID of the RBAC group permission to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_rbac_group_permission.rbac_group_permission_path_delete").delete(
    `${version}/rbac-group-permission/delete/${id}`
  )
}

/**
 * Removes multiple RBAC group permissions based on the provided data.
 * @param {object} data - Data specifying the RBAC group permissions to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function removeList(data) {
  return Client("root_role.role_path_batch_delete").post(
    `${version}/rbac-group-permission/delete/batch`,
    data
  )
}

// API object containing the exported functions.
const api = {
  gets,
  getListSelected,
  getDetail,
  create,
  update,
  remove,
  removeList
}

export default api
