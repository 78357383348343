import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches organizations with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_idm_organization.idm_organization_path_view").get(
    `${version}/organization/view/`,
    { params }
  )
}

/**
 * Fetches selected organizations with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getListSelected(params) {
  return Client("root_idm_organization.idm_organization_path_view").get(
    `${version}/organization/selected/`,
    { params }
  )
}

/**
 * Fetches the detail of an organization by ID.
 * @param {string} id - The ID of the organization.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_idm_organization.idm_organization_path_detail", "detail").get(
    `${version}/organization/detail/${id}`
  )
}

/**
 * Creates a new organization with the provided data.
 * @param {object} data - Data for creating the new organization.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_idm_organization.idm_organization_path_create").post(
    `${version}/organization/create/`,
    data
  )
}

/**
 * Updates an organization with the provided ID and data.
 * @param {string} id - The ID of the organization to be updated.
 * @param {object} data - Updated data for the organization.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_idm_organization.idm_organization_path_update").put(
    `${version}/organization/update/${id}`,
    data
  )
}

/**
 * Updates the status of organizations in batch with the provided data.
 * @param {object} data - Data for updating the status of organizations in batch.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function updateStatus(data) {
  return Client("root_idm_organization.idm_organization_path_batch_update_status").post(
    `${version}/organization/status/batch`,
    data
  )
}

/**
 * Removes an organization with the provided ID.
 * @param {string} id - The ID of the organization to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_idm_organization.idm_organization_path_delete").delete(
    `${version}/organization/delete/${id}`
  )
}

/**
 * Removes organizations in batch with the provided data.
 * @param {object} data - Data for removing organizations in batch.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function removeList(data) {
  return Client("root_idm_organization.idm_organization_path_batch_delete").post(
    `${version}/organization/delete/batch`,
    data
  )
}

/**
 * Fetches the detailed setting of an organization with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetailSetting(params) {
  return Client("root_idm_organization.idm_organization_setting.path_view").get(
    `${version}/organization/setting/detail/`,
    { params }
  )
}

/**
 * Fetches the own setting of an account with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getOwnSettingAccount(params) {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/org-settings/`, { params })
}

/**
 * Updates the setting of an organization with the provided ID and data.
 * @param {string} id - The ID of the organization setting to be updated.
 * @param {object} data - Updated data for the organization setting.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function updateSetting(id, data) {
  return Client("root_idm_organization.idm_organization_setting.path_update").put(
    `${version}/organization/setting/update/${id}`,
    data
  )
}

// API object containing the exported functions.
const api = {
  gets,
  getListSelected,
  getDetail,
  create,
  update,
  updateStatus,
  remove,
  removeList,
  getDetailSetting,
  getOwnSettingAccount,
  updateSetting
}

export default api
