import Client from "../client/ClientNotification"
import version from "../version"
const resource = version + "/notification"

function getCountNoti(params) {
  return Client().get(`${resource}/count/`, { params })
}
/**
 * Fetches notifications filtered by category with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getNotificationByCategory(params) {
  return Client().get(`${resource}/alls/`, { params })
}

/**
 * Fetches notification categories with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getCategories(params) {
  return Client().get(`${resource}/categories/`, { params })
}

/**
 * Fetches notifications with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function get(params) {
  return Client().get(`${resource}/`, { params })
}

/**
 * Creates a new notification with the provided data.
 * @param {object} data - Data for creating the new notification.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client().post(`${resource}/`, data)
}

/**
 * Marks a notification as read based on the provided notification ID.
 * @param {string} id - The ID of the notification to be marked as read.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function markReadByNoti(id) {
  return Client().put(`${resource}/markread/?noti_id=${id}`)
}

// API object containing the exported functions.
const api = {
  getCountNoti,
  getNotificationByCategory,
  get,
  create,
  markReadByNoti,
  getCategories
}

export default api
