import Client from "../client/ClientControlIDM"
import CPClient from "../client/ClientControlPlane"
import version from "../version"

/**
 * Fetches policies with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_policy.policy_path_view").get(`${version}/policy/view/`, { params })
}

/**
 * Fetches selected policies with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getListSelected(params) {
  return Client("root_policy.policy_path_view").get(`${version}/policy/selected/`, { params })
}

/**
 * Fetches the detail of a policy by ID.
 * @param {string} id - The ID of the policy.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_policy.policy_path_detail", "detail").get(`${version}/policy/detail/${id}`)
}

/**
 * Creates a new policy with the provided data.
 * @param {object} data - Data for creating the new policy.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_policy.policy_path_create").post(`${version}/policy/create/`, data)
}

/**
 * Updates a policy with the provided ID and data.
 * @param {string} id - The ID of the policy to be updated.
 * @param {object} data - Updated data for the policy.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_policy.policy_path_update").put(`${version}/policy/update/${id}`, data)
}

/**
 * Removes a policy with the provided ID.
 * @param {string} id - The ID of the policy to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_policy.policy_path_delete").delete(`${version}/policy/delete/${id}`)
}

/**
 * Removes multiple policies based on the provided data.
 * @param {object} data - Data specifying the policies to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function removeList(data) {
  return Client("root_policy.policy_path_batch_delete").post(`${version}/policy/delete/batch`, data)
}

/**
 * Fetches services with optional query parameters using CPClient.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getServices(params) {
  return CPClient("root_service.service_path_view").get(`${version}/internal/services/`, { params })
}

// API object containing the exported functions.
const api = {
  gets,
  getListSelected,
  getDetail,
  getServices,
  create,
  update,
  remove,
  removeList
}

export default api
