import Client from "../client/ClientControlIDM"
import version from "../version"

/**
 * Fetches a list of groups with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client("root_group.group_path_view").get(`${version}/group/view/`, { params })
}

/**
 * Fetches a list of selected groups with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getListSelected(params) {
  return Client("root_group.group_path_view").get(`${version}/group/selected/`, { params })
}

/**
 * Fetches detailed information for a specific group based on the provided ID.
 * @param {string} id - The ID of the group for which detailed information is requested.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function getDetail(id) {
  return Client("root_group.group_path_detail", "detail").get(`${version}/group/detail/${id}`)
}

/**
 * Creates a new group with the provided data.
 * @param {object} data - Data for creating the new group.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client("root_group.group_path_create").post(`${version}/group/create/`, data)
}

/**
 * Updates the information for a specific group based on the provided ID and data.
 * @param {string} id - The ID of the group to be updated.
 * @param {object} data - Data for updating the group.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client("root_group.group_path_update").put(`${version}/group/update/${id}`, data)
}

/**
 * Removes a specific group based on the provided ID.
 * @param {string} id - The ID of the group to be removed.
 * @returns {Promise} - A Promise that resolves with the result of the DELETE request.
 */
function remove(id) {
  return Client("root_group.group_path_delete").delete(`${version}/group/delete/${id}`)
}

/**
 * Removes multiple groups based on the provided data.
 * @param {object} data - Data for removing multiple groups.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function removeList(data) {
  return Client("root_group.group_path_batch_delete").post(`${version}/group/delete/batch`, data)
}

// API object containing the exported functions.
const api = {
  gets,
  getDetail,
  getListSelected,
  create,
  update,
  remove,
  removeList
}

export default api
