// Importing necessary components and styles from Ant Design and other modules
import { ConfigProvider, notification } from "antd"
import { StyleProvider } from "@ant-design/cssinjs"
import useStore, { StoreProvider } from "components/ui/Context"
import Routes from "components/Router"
import "utils/i18n"
import "./App.scss"
import { useEffect, useRef, useState } from "react"
import { changeLanguage } from "./utils/i18n"
import { getCookieAuth, getI18nextLng } from "lib/cookie"
import { debounce } from "utils/utils"
import { refreshAuthLogic } from "utils/request"
import { ModalConfirm } from "components/Modal"
import { removeAuthLocal } from "lib/localstorage"
import { removeCookies } from "lib/cookie"

// Configuring default notification settings
notification.config({
  top: 0, // Notification placement from the top of the screen
  duration: 3 // Notification display duration in seconds
})

function Interval(fn, time) {
  let timer = false
  this.start = function () {
    if (!this.isRunning()) timer = setInterval(fn, time)
  }
  this.stop = function () {
    clearInterval(timer)
    timer = false
  }
  this.isRunning = function () {
    return timer !== false
  }
}

// Main component representing the entire application
function App() {
  const { access_token } = useStore()
  const isUserActiveManual = useRef(false)
  const isStopForceLogout = useRef(false)
  const [isShow, setIsShow] = useState()

  let interactiveEvents = [
    "mousemove",
    "keydown",
    "mousedown",
    "pointerdown",
    "pointerup",
    "touchend"
  ]

  const forceLogoutInterval = new Interval(() => {
    if (!isStopForceLogout.current) handleLogOut()
  }, 60000 * 1)

  const showLogoutModalInterval = new Interval(() => {
    if (!!getCookieAuth()?.refresh_token) {
      isStopForceLogout.current = false
      setIsShow(true)
      forceLogoutInterval.start()
    }
  }, 60000 * 14)

  const intervalActive = new Interval(() => {
    isUserActiveManual.current = false
  }, 5000)

  const checkUserActiveInterval = new Interval(() => {
    if (isUserActiveManual.current) {
      showLogoutModalInterval.stop()
    } else {
      showLogoutModalInterval.start()
    }
  }, 5000)

  const handleUserActivationManual = () => {
    if (isUserActiveManual.current) return
    isUserActiveManual.current = true
    intervalActive.stop()
    intervalActive.start()
  }

  const handleLogOut = () => {
    removeAuthLocal()
    removeCookies()
    window.location.href = "/login"
  }

  const handleRefreshToken = () => {
    isStopForceLogout.current = true
    showLogoutModalInterval.stop()
    setIsShow(false)
    refreshAuthLogic()
  }

  useEffect(() => {
    if (access_token) {
      changeLanguage(getI18nextLng())
    }
    if (!!getCookieAuth()?.refresh_token) {
      checkUserActiveInterval.start()
      interactiveEvents.forEach((event) =>
        window.addEventListener(event, debounce(handleUserActivationManual))
      )
      return () => {
        interactiveEvents.forEach((event) =>
          window.removeEventListener(event, debounce(handleUserActivationManual))
        )
        intervalActive.stop()
        checkUserActiveInterval.stop()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    // Ant Design ConfigProvider for global configuration settings
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1C3F38", // Customizing the primary color for tokens
          borderRadius: 4, // Customizing the border radius for tokens
          fontFamily: "Inter" // Customizing the font family for tokens
        }
      }}
    >
      {/* Ant Design StyleProvider for customizing CSS-in-JS styles */}
      <StyleProvider hashPriority="high">
        {/* Custom StoreProvider for managing global state using React context */}
        <StoreProvider>
          {/* Component rendering application routes */}
          <Routes />
          <ModalConfirm
            visible={isShow}
            onCancel={() => handleLogOut()}
            onSubmit={() => handleRefreshToken()}
          />

          {/* Placeholder for mounting modals outside the normal component hierarchy */}
          <div id="modal-root"></div>
        </StoreProvider>
      </StyleProvider>
    </ConfigProvider>
  )
}

// Exporting the App component as the default export
export default App
