import Client from "../client/ClientCommon"
import version from "../version"

// The resource path is constructed by appending the version and "/activity/logs" to form the base path.
const resource = version + "/activity/logs"

/**
 * Fetches a list of activity logs with optional query parameters.
 * @param {object} params - Optional query parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function gets(params) {
  return Client.get(`${resource}/`, { params })
}

/**
 * Fetches detailed information for a specific activity log based on the provided parameters.
 * @param {object} params - Parameters for the request.
 * @returns {Promise} - A Promise that resolves with the result of the GET request.
 */
function get(params) {
  return Client.get(`${version}/activity/detail/`, { params })
}

/**
 * Creates a new activity log entry with the provided data.
 * @param {object} data - Data for creating the new activity log entry.
 * @returns {Promise} - A Promise that resolves with the result of the POST request.
 */
function create(data) {
  return Client.post(`${resource}/`, data)
}

/**
 * Updates an existing activity log entry with the specified ID using the provided data.
 * @param {string} id - The ID of the activity log entry to be updated.
 * @param {object} data - Data for updating the activity log entry.
 * @returns {Promise} - A Promise that resolves with the result of the PUT request.
 */
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}

// API object containing the exported functions.
const api = {
  gets,
  get,
  create,
  update
}

export default api
